// extracted by mini-css-extract-plugin
export var column = "BannerCaseStudy__column__agji8";
export var ctaWrapper = "BannerCaseStudy__ctaWrapper__d0qvS";
export var description = "BannerCaseStudy__description__av86Z";
export var row = "BannerCaseStudy__row__VyoF4";
export var testimonialText = "BannerCaseStudy__testimonialText__RtnPa";
export var testimonialWrapper = "BannerCaseStudy__testimonialWrapper__xThj7";
export var title = "BannerCaseStudy__title__rpW8Q";
export var wrapper = "BannerCaseStudy__wrapper__TqEBc";
export var wrapperBackgroundImage = "BannerCaseStudy__wrapperBackgroundImage__FE2AL";
export var wrapperContainer = "BannerCaseStudy__wrapperContainer__yzwiA";