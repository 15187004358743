// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../../../styleguide/components/Heading/H2.res.js";
import * as BannerLearnMore from "../../../../../styleguide/components/BannerLearnMore/BannerLearnMore.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import IconZeroOpsSvg from "../assets/icon-zero-ops.svg";
import IconMulticloudAiSvg from "../assets/icon-multicloud-ai.svg";
import * as LearnMoreSectionMulticloudScss from "./LearnMoreSectionMulticloud.scss";
import IconProductionReadySvg from "../assets/icon-production-ready.svg";
import MulticloudSpendLessSvg from "../assets/multicloud-spend-less.svg";

var css = LearnMoreSectionMulticloudScss;

var multicloudSpendLessSvg = MulticloudSpendLessSvg;

var iconZeroOpsSvg = IconZeroOpsSvg;

var iconMulticloudAiSvg = IconMulticloudAiSvg;

var iconProductionReadSvg = IconProductionReadySvg;

function LearnMoreSectionMulticloud(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx(H2.make, {
                              children: "What is Multicloud?"
                            }),
                        JsxRuntime.jsx("p", {
                              children: "Multicloud is the practice of using more than one cloud service providers (AWS, GCP and Azure) across one or more regions. At Datacenters.com, we integrate your microservices with a multicloud platform-as-a-service (PaaS) so that your software can be managed and deployed in one easy-to-use service. The multicloud platform allows your team to deploy in seconds and have complete control while drastically reducing the cost of DevOps."
                            })
                      ],
                      className: css.header
                    }),
                JsxRuntime.jsx(BannerLearnMore.make, {
                      title: "Spend Less on Cloud",
                      description: "The multicloud platform ensures you can control costs because you only pay for the CPU/RAM/egress workloads you consume.",
                      isReversed: false,
                      svgIllustration: multicloudSpendLessSvg
                    }),
                JsxRuntime.jsx(BannerLearnMore.make, {
                      title: "Zero Ops",
                      description: "Deploy and operate 10x faster while focusing 100% on development and 0% on Ops. The multicloud platform handles scaling, load balancing, secrets management, certificates, logging, metrics, monitoring, alerting, key rotation, K8s, service mesh, MTLS, ingress, etc.",
                      isReversed: true,
                      svgIllustration: iconZeroOpsSvg
                    }),
                JsxRuntime.jsx(BannerLearnMore.make, {
                      title: "Multicloud AI",
                      description: "The platform leverages artificial intelligence to scale and manage multicloud deployments. The AI learns and adapts to the demands on cloud services to automatically reduce runtime costs.",
                      isReversed: false,
                      svgIllustration: iconMulticloudAiSvg
                    }),
                JsxRuntime.jsx(BannerLearnMore.make, {
                      title: "Production Ready Guaranteed",
                      description: "Use fine-grained access control to run production-grade backends confidently. Workloads scale up and down, log data, track metrics, and are highly secure.",
                      isReversed: true,
                      svgIllustration: iconProductionReadSvg
                    })
              ]
            });
}

var make = LearnMoreSectionMulticloud;

export {
  css ,
  multicloudSpendLessSvg ,
  iconZeroOpsSvg ,
  iconMulticloudAiSvg ,
  iconProductionReadSvg ,
  make ,
}
/* css Not a pure module */
