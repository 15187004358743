// extracted by mini-css-extract-plugin
export var button = "PricingEstimatorModal__button__eJlvY";
export var column = "PricingEstimatorModal__column__D_bf6";
export var endColumn = "PricingEstimatorModal__endColumn__V8nqo";
export var estimatedTotal = "PricingEstimatorModal__estimatedTotal__lEyua";
export var estimatedTotalDuration = "PricingEstimatorModal__estimatedTotalDuration__ZdG5q";
export var estimatedTotalNoCommitmentSubtitle = "PricingEstimatorModal__estimatedTotalNoCommitmentSubtitle__Qo_lZ";
export var estimatedTotalNoCommitmentTitle = "PricingEstimatorModal__estimatedTotalNoCommitmentTitle__L0Xyb";
export var estimatedTotalPrice = "PricingEstimatorModal__estimatedTotalPrice__j5WZP";
export var modalBody = "PricingEstimatorModal__modalBody___wctE";
export var modalFooter = "PricingEstimatorModal__modalFooter__kzViI";
export var modalFooterWrapper = "PricingEstimatorModal__modalFooterWrapper__pSxRM";
export var modalHeader = "PricingEstimatorModal__modalHeader__m6LVG";
export var modalHeaderWrapper = "PricingEstimatorModal__modalHeaderWrapper__BG6m1";
export var modalRoot = "PricingEstimatorModal__modalRoot__mfoM5";
export var pricingEstimatorDescription = "PricingEstimatorModal__pricingEstimatorDescription__Rf8jo";
export var row = "PricingEstimatorModal__row__AUMWV";
export var scheduleDemo = "PricingEstimatorModal__scheduleDemo__F70Tf";
export var scheduleDemoDescription = "PricingEstimatorModal__scheduleDemoDescription__Dd_GS";
export var scheduleDemoForm = "PricingEstimatorModal__scheduleDemoForm__dQEJP";
export var startColumn = "PricingEstimatorModal__startColumn__zqOQe";